import Vue from 'vue'
import capitalize from 'lodash/capitalize'
import numeral from 'numeral'
import sanitizeHtml from 'sanitize-html'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

const ONE_DAY = 24 * 60 * 60
const RESOLUTION_MAP = {
  '1280x720': 'HD',
  '1920x1080': 'FHD',
  '3840x2160': 'UHD',
}

// Para usar nos métodos:
//   this.$options.filters.

Vue.filter('currency', value => {
  if (value >= 0) {
    return '€' + value.toFixed(2).toLocaleString()
  }
  return null
})

Vue.filter('sanitizeAnyHtml', function(value) {
  return sanitizeHtml(value, { allowedTags: [] })
})

Vue.filter('currency', value => {
  if (value >= 0) {
    return '€' + value.toFixed(2).toLocaleString()
  }
  return null
})

Vue.filter('prettyBytes', function(num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  var exponent
  var unit
  var neg = num < 0
  var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1)
  num = (num / Math.pow(1024, exponent)).toFixed(2) * 1
  unit = units[exponent]

  return (neg ? '-' : '') + num + ' ' + unit
})

Vue.filter('formatNumber', function(value) {
  return numeral(value).format('0.0 b')
})

Vue.filter('formatDecimal', function(value) {
  /**
   * Without "Math.round((value + Number.EPSILON) * 100) / 100" the rounding uses bankers rounding rules
   * where if the cent value is odd, the 0.5 (half-cent) rounds upwards; If the cent value is even, the half-cent rounds it downwards.
   *
   * Bankers rounding rules:
   *  1.865 -> 1.86
   *
   * With "Math.round((value + Number.EPSILON) * 100) / 100":
   * 1.865 -> 1.87
   */
  return numeral(Math.round((value + Number.EPSILON) * 100) / 100).format('0,0.00')
})

Vue.filter('formatPercentage', function(value) {
  return numeral(value).format('0')
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return Dates.formatCore(value, 'DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return Dates.formatCore(value, 'DD/MM/YYYY H:mm')
  }
})

Vue.filter('humanDateTimePT', function(value) {
  if (value) {
    let date = Dates.buildCore(value)
    if (date.isToday()) {
      return date.format('H:mm')
    } else if (date.isYesterday()) {
      return 'Ontem ' + date.format('H:mm')
    } else if (date.isTomorrow()) {
      return 'Amanhã ' + date.format('H:mm')
    } else {
      return date.format('DD/MM/YYYY H:mm')
    }
  }
})

Vue.filter('formatDateTimeSeconds', function(value) {
  if (value) {
    return Dates.formatCore(value, 'DD/MM/YYYY H:mm:ss')
  }
})

Vue.filter('formatDayWithTime', function(value) {
  if (value) {
    return Dates.formatCore(value, 'DD/MM H:mm')
  }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return Dates.formatCore(value, 'H:mm')
  }
})

Vue.filter('formatTimeFromTime', function(value) {
  if (value) {
    return Dates.formatCore(Dates.from(value, 'HH:mm:ss'), 'H:mm')
  }
})

Vue.filter('formatTimeSeconds', function(value) {
  if (value) {
    return Dates.formatCore(value, 'H:mm:ss')
  }
})

Vue.filter('formatMonthDate', function(value) {
  if (value) {
    return Dates.buildCore(value).format('MM/YYYY')
  }
})

Vue.filter('shortDateDaysLeft', function(deadline) {
  if (deadline) {
    return Dates.nowDiff(deadline, 'days')
  }
})

Vue.filter('shortDateHoursLeft', function(deadline) {
  if (deadline) {
    return Dates.nowDiff(deadline, 'hours')
  }
})

Vue.filter('secondsToTime', function(seconds) {
  if (seconds === 0) return '00:00:00'
  else if (!seconds || seconds >= ONE_DAY) return '-:-:-'
  return new Date(seconds * 1000).toISOString().substr(11, 8)
})

Vue.filter('prettySeconds', function(seconds) {
  if (seconds < 60) {
    return seconds + ' segundo(s)'
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    const mSeconds = seconds % 60
    if (mSeconds > 0) return minutes + ' minuto(s)' + mSeconds + ' segundo(s)'
    else return minutes + 'm'
  }

  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    const hMinutes = minutes % 60
    if (hMinutes > 0) return hours + ' hora(s) ' + hMinutes + ' minuto(s)'
    else return hours + ' hora(s)'
  }

  const days = Math.floor(hours / 24)
  const dHours = hours % 24
  if (dHours > 0) return days + ' dia(s) ' + dHours + ' hora(s)'
  else return days + ' dia(s)'
})

Vue.filter('prettyResolution', function(string) {
  return RESOLUTION_MAP[string] || string
})

Vue.filter('formatDeadline', function(deadline) {
  if (deadline) {
    const now = Dates.now()
    const deadlineObj = Dates.build(deadline + ' 23:59:59')

    if (now > deadlineObj) {
      return deadline
    } else if (now == deadlineObj) {
      return 'hoje'
    }

    const daysLeft = deadlineObj.diff(now, 'days')
    if (daysLeft == 1) {
      return 'amanhã'
    } else {
      return 'dentro de ' + daysLeft + ' dias'
    }
  }
})

Vue.filter('capitalize', function(value) {
  if (value) {
    return capitalize(String(value))
  }
})

Vue.filter('getExtension', function(value) {
  var regex = /(?:\.([^.]+))?$/
  return regex.exec(value)[1]
})

Vue.filter('reverse', function(value) {
  return value.slice().reverse()
})

Vue.filter('bytesToString', function(value) {
  return utils.bytesToString(value)
})
