<template>
  <div class="w-full group h-full" :class="{ 'cursor-pointer': !noLink, shadow: noLink }">
    <div
      class="rounded-xl overflow-hidden aspect-w-16 aspect-h-9 w-full shadow-lg hover:shadow-2xl bg-no-repeat bg-cover bg-center"
      :class="{ 'mb-2': !marginless }"
      :style="`background-image: url('${thumbUrl}')`"
    >
      <div>
        <div v-if="$slots.overlay" class="w-full h-full absolute z-10">
          <slot name="overlay"></slot>
        </div>
      </div>
      <div
        v-if="!noBgBlur"
        class="h-full w-full top-0 left-0 absolute bg-opacity-85 group-hover:bg-opacity-95"
        style="z-index: 0"
        :class="{
          'backdrop-blur-xl': type == 'blur-xl',
          'backdrop-blur-sm': type == 'blur-sm',
          'as-cover': type == 'cover',
        }"
      ></div>
    </div>
    <fw-label v-if="tag" color="text-primary" uppercase class="mt-2">{{ tag }}</fw-label>
    <div v-if="title" class="w-full text-md font-bold">
      <v-clamp v-if="!noClamp" autoresize :max-lines="2">
        {{ title }}
      </v-clamp>
      <div v-else>
        {{ title }}
      </div>
    </div>
    <div v-if="$slots.main" class="flex flex-col flex-1 items-center justify-center my-2">
      <slot name="main"></slot>
    </div>
    <div v-if="$slots.footer" class="text-xs flex items-center justify-between gap-3 text-gray-500 mt-2">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marginless: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'cover',
    },
    title: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: null,
    },
    thumbUrl: {
      type: String,
      default: null,
    },
    noClamp: {
      type: Boolean,
      default: false,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
    noBgBlur: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>
.as-cover {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 0.95) 25%, rgba(50, 68, 87, 0.35) 85%);
}
</style>
