<template>
  <div class="select-services-screen">
    <fw-heading size="h1"> Fazer marcação </fw-heading>
    <!-- Select Service -->
    <div class="services-section mt-2">
      <div>
        <div class="flex gap-5 items-center mb-5">
          <fw-button class="flex gap-2 items-center" type="tab" size="md" @click.native="chooseBySubject">
            Escolher por assunto
          </fw-button>
          <fw-button class="flex gap-2 items-center" type="tab-active" size="md" @click.native="chooseByQueues">
            Escolher por Fila
          </fw-button>
        </div>
      </div>
      <h2 v-show="false" class="ml-4 my-5 opacity-50 font-semibold text-xl">
        <!-- LOCALE STRING: Seleccione os serviços -->
        {{ localeMessages['title'] }}
      </h2>
      <fw-label v-if="currentPage === 'service_selection_ticket'">
        <!-- LOCALE STRING: Escolha os vários serviços em que quer tirar senha -->
        {{ localeMessages['ticket_message'] }}
      </fw-label>
      <fw-label v-else>
        <!-- LOCALE STRING: Escolha os vários serviços em que quer fazer marcação -->
        {{ localeMessages['appointment_message'] }}
      </fw-label>
      <fw-panel v-for="(service, s) in services" :key="'service_box_' + s" :title="service.name" class="my-5">
        <div class="flex items-center gap-2 flex-col">
          <option-checkbox
            v-for="(queue, q) in service.queues"
            :key="'queue_' + s + q"
            :disabled="queueNeedAuth(queue.id) && !userLoggedIn"
            @selected="selectService(s, q, queue.id, service.name, queue.description, queue.subjects, queue.name)"
          >
            {{ queue.description }}
            <div v-if="queueNeedAuth(queue.id) && !userLoggedIn" class="flex gap-2 items-center text-gray-500">
              <svg
                aria-hidden="true"
                height="12"
                focusable="false"
                data-prefix="fas"
                data-icon="lock"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class=" h-3.5 w-3.5"
              >
                <path
                  fill="currentColor"
                  d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                  class=""
                ></path>
              </svg>
              {{ localeMessages['queue_needs_authentication'] }}
            </div>
          </option-checkbox>
        </div>
      </fw-panel>
      <div v-if="nocontent" class="no-data-msg">Sem nenhum serviço disponível</div>
      <!-- Section clear -->
      <div class="clear-both"></div>
    </div>
  </div>
</template>

<script>
import OptionCheckbox from '../components/OptionCheckbox'

const needsAuth = [58]

export default {
  name: 'SelectServicesScreen',
  components: {
    'option-checkbox': OptionCheckbox,
  },
  data() {
    return {
      needsAuth: needsAuth,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    localeMessages() {
      return this.$store.state.locales['select_services']
    },
    currentPage() {
      return this.$store.getters.currentPage
    },
    services() {
      if (this.currentPage === 'service_selection_booking') {
        return this.$store.getters.servicesScheduleAllowed
      } else if (this.currentPage === 'service_selection_ticket') {
        return this.$store.getters.servicesTicketAllowed
      } else {
        return []
      }
    },
    nocontent() {
      return this.services.length === 0
    },
  },
  mounted() {
    this.$store.dispatch('resetSelectedServices')
  },
  methods: {
    queueNeedAuth(id) {
      return this.needsAuth.includes(id)
    },
    chooseBySubject() {
      this.$store.dispatch('openPage', 'subject_selection_screen')
    },
    selectService(s, q, queueID, serviceName, queueName, subjects, queueLetter) {
      //check if it needs auth
      if (this.needsAuth.includes(queueID) && !this.userLoggedIn) {
        return
      }
      this.$store.commit('selectService', {
        s: s,
        q: q,
        qid: queueID,
        q_name: queueName,
        s_name: serviceName,
        subjects: subjects,
        queueLetter: queueLetter,
      })
    },
  },
}
</script>

<style scoped>
.choose-by-queues {
  @apply w-full;
  @apply sm:px-7 py-5 px-8;
  @apply bg-gray-50 rounded-2xl shadow-md mb-3 cursor-pointer text-gray-700;
}
.choose-by-queues:hover {
  @apply shadow-xl;
}
.icon-exchange {
  @apply text-gray-500 text-lg inline-block h-5 w-5 mr-4;
}

.services-section {
  @apply text-left;
}
.service-box {
  @apply w-full;
  @apply p-5;
  @apply bg-white rounded-2xl shadow-md mb-3;
}
.service-box h3 {
  @apply font-bold text-xl mb-5;
}
.queue {
  @apply flex justify-start my-2;
  @apply float-left sm:float-none mr-2 sm:mr-0;
}
.queue.selectable {
  @apply cursor-pointer select-none;
}
.queue.selectable:hover {
  @apply ring-4 ring-gray-300 ring-opacity-80;
}
.queue h4 {
  @apply self-center pl-4 font-medium;
  @apply hidden sm:inline-block;
}
.queue.selected h4 {
  @apply font-bold;
}
.dashboard {
  background-color: #212121;
  @apply rounded-2xl text-white text-left px-5 pt-3 pb-5;
}
.dashboard h3 {
  @apply font-bold mb-1;
}
.dashboard .dashboard-section {
  @apply sm:w-full sm:w-1/2 relative;
}
.dashboard .dashboard-section .see-more {
  @apply cursor-pointer absolute top-0.5 right-0.5 text-sm font-semibold select-none;
}
.dashboard .dashboard-section .see-more .caret-down {
  @apply h-3.5 inline ml-1;
}
.dashboard .time-box {
  @apply w-16 text-center font-bold;
  min-width: 4rem;
}
.dashboard .next-schedule {
  @apply flex py-2;
}
.dashboard .next-schedule h4 {
  @apply self-center pl-4 font-medium;
}
</style>
