const en = {
  app: {
    description: 'Jobs at University of Coimbra',
    client: 'University of Coimbra',
  },
  label: {
    keepActiveSession: 'Keep active session',
    login: 'Login',
  },
  message: {
    hello: 'Hello world',
    internalErrorFound: 'Ocorreu um erro interno...',
    login: {
      loginFailed: 'Wrong password. Try again or click Forgot password to reset it.',
    },
    form: {
      errorsFound: 'O formulário contém erros.',
      required: 'Required',
      emailRequired: 'Indique um endereço de email válido.',
      passwordRequired: 'Introduza a password.',
    },
  },
  summary: {
    recoverPassword: 'Enter your email address',
    login: '',
  },
  error: {
    notfound: {
      title: 'Not found',
      description: 'The resource requested could not be found.',
    },
  },
  title: {
    login: 'Login',
    welcome: 'Welcome',
    account: 'Account',
    notifications: 'Notifications',
    balance: 'Balance',
    invoices: 'Invoices',
    mb: 'MB Payment',
    credentials: 'Credentials',
    transfer: 'Credit Transfer',
    refund: 'Refund',
    features: 'Funcionalidades',
    recoverPassword: 'Recuperar conta',
  },
  link: {
    home: 'Home',
    next: 'Next',
    login: 'Login',
    registerAccount: 'Register account',
    recoverPassword: 'Esqueceu-se da password?',
    balance: 'Balanço',
    credentials: '@:title.credentials',
    transfer: '@:title.transfer',
    invoices: '@:title.invoices',
    mb: 'MB',
    refund: '@:title.refund',
    anotherAccount: 'Other account?',
  },
  /**
   * Mensagens UC Student Hub
   */
  time: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    months_abbreviated: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  },
  add_appointment_contact: {
    title: 'Get an Appointment',
    subtitle: 'How can we get in touch?',
    option_email: 'By e-mail',
    option_phone: 'By phone number',
    message_option_phone: 'Tell us your phone number',
    message_option_email: 'Tell us your e-mail address',
    message_sms_sent_part1: 'An SMS code was sent to the phone ',
    message_sms_sent_part2: 'Please, insert it here:',
    placeholder_sms_code: 'SMS Code',
    placeholder_phone_number: 'Phone Number',
    placeholder_email: 'E-mail Address',
  },
  appointment_confirmed: {
    title: 'Confirmed!',
    add_google_calendar: '+ Add to Google Calendar',
    get_directions: 'Get Directions',
  },
  home_page: {
    address_description: `O Student Hub está localizado no rés-do-chão do Edifício da Faculdade de Medicina, na Rua Larga.`,
    openhours_description: `O horário dos serviços de apoio é de segunda a sexta-feira das 9h30 às 13h00 e das 14h00 às 17h00.<br>O horário das salas disponibilizadas pelo Student Hub é de segunda a sexta-feira das 9h00 às 20h00.`,
    dashboard: {
      your_tickets: 'Your tickets',
      next_appointment: 'Next appointment',
      appointments: 'appointments',
      see_all_appointments: 'See all',
      title_all_appointments: 'All appointments',
    },
    queues: 'Services',
    queue_card: {
      get_ticket: 'Get Ticket',
      ticket_not_available: "It's not possible to get a ticket",
      login_to_get_ticket: 'Login to',
      login_to_get_ticket_part2: 'get ticket',
    },
    get_directions: 'Get Directions',
    address: 'Address',
    map_location: 'See location on a map',
    schedule: 'Open hours',
    thespace: 'The space',
  },
  needs_authentication: {
    title: 'Login',
    message: 'Getting tickets online is a feature reserved to University of Coimbra community.',
  },
  select_services: {
    title: 'Select services',
    ticket_message: 'Choose one or multiple services where you want a ticket',
    appointment_message: 'Choose one or multiple services where you want to get an appointment',
    queue_needs_authentication: 'Login to be able to make an appointment for this service',
  },
  add_appointment_details: {
    title: 'Make Appointment',
    select_subject: 'Subject(s)',
    optional: 'Optional',
    message_subject_part1: 'Helps us filtering your request selecting one',
    message_subject_part2: '(or multiple) subject(s)',
    add_details: 'Describe the issue',
    mandatory: 'Mandatory',
    message_details: 'Describe what you want/need',
    message_option_phone: 'Tell us your phone number',
    placeholder_details: 'How can we help you?',
    sms_not_received: "Haven't received the SMS code?",
    ask_new_sms_code: 'Ask new SMS code',
    retry_sms_msg1: 'Ask a new code in ',
    retry_sms_msg2: ' seconds',
    full_name: 'Full Name',
    email: 'E-mail Address',
    phone: 'Phone Number',
  },
  select_appointments_schedule: {
    title: 'See all the schedules available',
    day_summary_slots: 'slots',
  },
  smart_schedule_results: {
    title: 'Schedule suggestions',
    message: 'We found the following available time slots you might want',
    see_all: 'See all available time slots',
    error_message: "Sorry, but we couldn't find a compatible schedule.<br />Please select your options manually:",
  },
  smart_schedule_selection: {
    title: 'Schedule suggestions',
    message: 'Select your preference so we can find the best schedule.',
    confort_option: 'Comfort',
    confort_option_message: 'We will try to find available time slots in the same day.',
    urgent_option: 'Urgent',
    urgent_option_message: 'See the next available time slots',
    very_urgent_option: 'Very Urgent',
    very_urgent_option_message: 'I want to be seen now',
    see_all_schedule: 'See all available time slots',
  },
  ticket_confirmed: {
    title: 'Your tickets',
    get_directions: 'Get Directions',
  },
  bottom_bar: {
    get_ticket: 'Get Ticket',
    authenticate: 'Login',
    get_appointment: 'Get Appointment',
    close: 'Close',
    sendSMScode: 'Send SMS code',
    getAppointment: 'Get Appointment',
    confirmLater: 'Confirm later',
    getTickets: 'Get tickets',
    next: 'Next',
  },
  navigation_text: {
    selectSubject: 'Select subjects',
    select_services: 'Select services', //Seleccione os serviços
    schedule_suggestions: 'Schedule Suggestion', //Sugestões de horários
    select_schedule: 'Choose a time', //Escolha o horário
  },
  ticket: {
    wait: 'Wait',
    post: 'Post',
  },
  error_messages: {
    makeAppointment: 'The was an error booking the appointment. Please try again later.',
    sendSMSCode: "Sorry, but the SMS couldn't be sent. Try again later.",
    getTickets: 'There was an error processing your request.',
    confirmAppointments: 'The was an error confirming the appointments. Try again later.',
    cancelAppointment: 'The was an error canceling the appointment. Try again later.',
    scheduled_call:
      'You already have an appointment for this service.<br />If you want to change it, cancel your appointment first.',
    getAppointmentStatus: 'There was an error getting the information about your appointment(s). Try again later.',
    confirmationCode: 'The confirmation code is not valid.',
  },
  success_messages: {
    confirmAppointments: 'The appointments are confirmed.',
    cancelAppointment: 'The appointment was successfully cancelled.',
  },
  appointment_status: {
    title: 'Appointment(s) Status',
    unnavialable_info:
      'The information about your appointment(s) are not available at the moment. Please try again later',
  },
  appointment: {
    tooltip_how_to_confirm: 'Confirm the appointment by clicking in the e-mail sent',
    status_not_confirmed: 'Not confirmed',
    status_canceled: 'Canceled',
    cancelation_message: 'Reason for cancellation',
    cancel_appointment: 'Cancel Appointment',
    add_google_calendar: '+ Add to Google Calendar',
  },
  confirm_email: {
    one_step_more: 'Only one more step!',
    title: 'Confirm e-mail',
    message_part1: 'An e-mail was sent to',
    message_part2: 'Please click on the link to confirm the ownership of the e-mail.',
    open_webmail: 'Open ',
  },
  weekdays: {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  },
}

export default en
