<template>
  <div>
    <figure
      class="relative"
      :class="[
        {
          'scale-50 opacity-50': !loaded && animated,
          'border border-primary': !borderless && loggedUser && user && loggedUser.key === user.key,
        },
        style(),
      ]"
    >
      <img v-if="user && getPhotoUrl" :src="getPhotoUrl" class="brightness-105 contrast-125" />
      <span v-else-if="user">{{ user.initials }}</span>
      <span v-else-if="brand">{{ brand }}</span>
      <fw-icon-group v-else-if="group" class="w-full h-full p-1" :class="{ 'md:p-2': size == 'xl' }" />
      <component
        :is="'fw-icon-' + icon"
        v-else-if="icon"
        class="w-full h-full p-2"
        :class="{ 'md:p-4': size == 'xl' }"
      />
      <fw-icon-user2 v-else class="w-full h-full p-2" :class="{ 'md:p-4': size == 'xl' }" />
    </figure>
    <fw-dot v-if="showAsOnline" :ping="false" class="-top-0.5 -right-0.5" />
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  props: {
    user: {
      type: Object,
    },
    brand: String,
    icon: String,
    group: Object,
    animated: Boolean,
    borderless: Boolean,
    showAsOnline: Boolean,
    size: {
      type: String,
      default: 'sm',
    },
    customClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      assetsPath: process.env.VUE_APP_ROUTER_BASE || '/',
      loaded: false,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    getPhotoUrl() {
      if (!this.user) return false
      const sizeMap = {
        xs: 'small',
        sm: 'small',
        md: 'medium',
        lg: 'medium',
        xl: 'large',
        full: 'large',
        auto: 'medium',
      }
      return ServiceStorage.getUserImageViewUrl(this.user, sizeMap[this.size])
    },
    getLocalUserPhoto() {
      return this.$store.getters.getLocalUser.photoUrl
    },
  },

  mounted() {
    if (this.animated) {
      setTimeout(() => {
        this.loaded = true
      }, 150)
    }
  },

  methods: {
    style() {
      let classString = `${this.group ? 'group' : 'user'}-avatar avatar-${this.size}`
      if (this.customClass) {
        classString = classString + ' ' + this.customClass
      }
      return classString
    },
  },
}
</script>

<style lang="scss">
.user-avatar,
.group-avatar {
  @apply overflow-hidden focus:outline-none shadow-md transition-all duration-200 opacity-100 flex items-center justify-center font-bold text-sm bg-white w-full h-full text-gray-600;
  @apply w-9 h-9 rounded-xl;

  &.avatar-2xs {
    @apply w-6 h-6 rounded-lg text-xs leading-5 font-semibold;
  }

  &.avatar-xs {
    @apply w-8 h-8 rounded-lg text-xs leading-5 font-semibold;
  }

  &.avatar-sm {
    @apply w-10 h-10 text-sm rounded-xl font-semibold;
  }

  &.avatar-base {
    @apply w-12 h-12 text-lg rounded-xl font-semibold;
  }

  &.avatar-md {
    @apply w-16 h-16 text-xl rounded-2xl font-semibold;
  }

  &.avatar-lg {
    @apply w-20 h-20 text-2xl rounded-3xl font-bold;
  }

  &.avatar-xl {
    @apply w-24 h-24 text-5xl rounded-3xl font-bold;
  }

  &.avatar-full {
    @apply w-full h-full rounded-xl;
  }

  &.avatar-manual {
    @apply rounded-xl;
  }
}
.group-avatar {
  @apply bg-gradient-to-r from-teal-200 to-teal-400 hover:to-teal-500 text-teal-700;
}
.user-avatar {
  @apply font-semibold text-white bg-gradient-to-r from-gray-600 to-gray-800;
}
</style>
