const pt = {
  app: {
    description: 'Procedimentos Concursais na Universidade de Coimbra',
    client: 'University of Coimbra',
  },
  label: {
    keepActiveSession: 'Manter sessão ativa',
    login: 'Entrar',
  },
  message: {
    hello: 'Olá mundo',
    internalErrorFound: 'Ocorreu um erro interno...',
    login: {
      loginFailed: 'Email ou password incorreta. Tente novamente ou clique em recuperar a password.',
    },
    form: {
      errorsFound: 'O formulário contém erros.',
      required: 'Obrigatório',
      emailRequired: 'Indique um endereço de email válido.',
      passwordRequired: 'Introduza a password.',
    },
  },
  error: {
    notfound: {
      title: 'Não encontrado',
      description: 'O recurso solicitado não foi encontrado.',
    },
    auth: {
      loginFailed: 'O seu email ou password não estão corretos.',
    },
  },
  title: {
    login: 'Autenticação',
    welcome: 'Bem-vindo',
    account: 'Perfil',
    notifications: 'Notificações',
    balance: 'Saldo e movimentos de conta',
    invoices: 'Faturas',
    mb: 'Referências MB para carregamento',
    credentials: 'Credenciais',
    transfer: 'Transferência de crédito',
    refund: 'Devolução',
    features: 'Funcionalidades',
    recoverPassword: 'Recuperar conta',
  },
  summary: {
    recoverPassword: 'Enter your email address',
    login: 'Plataforma de gestão de procedimentos concursais da Universidade de Coimbra.',
  },
  link: {
    home: 'Início',
    next: 'Continuar',
    login: 'Entrar',
    registerAccount: 'Registar conta',
    recoverPassword: 'Esqueceu-se da password?',
    balance: 'Balanço',
    credentials: '@:title.credentials',
    transfer: '@:title.transfer',
    invoices: '@:title.invoices',
    mb: 'MB',
    refund: '@:title.refund',
    anotherAccount: 'Outra conta?',
  },
  /**
   * Mensagens UC Student Hub
   */
  time: {
    today: 'Hoje',
    tomorrow: 'Amanhã',
    months_abbreviated: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    weekdays: ['2ª feira', '3ª feira', '4ª feira', '5ª feira', '6ª feira', 'Sábado', 'Domingo'],
  },
  add_appointment_contact: {
    title: 'Fazer Marcação',
    subtitle: 'Como o podemos contactar?',
    option_email: 'Por e-mail',
    option_phone: 'Por telemóvel',
    message_option_phone: 'Indique-nos o seu número de telemóvel',
    message_option_email: 'Indique-nos o seu endereço de e-mail',
    message_sms_sent_part1: 'Foi enviado um código por SMS para o número',
    message_sms_sent_part2: 'Por favor, insira-o abaixo:',
    placeholder_sms_code: 'Código SMS',
    placeholder_phone_number: 'Número de telemóvel',
    placeholder_email: 'Endereço de e-mail',
    sms_not_received: 'Não recebeu o código SMS?',
    ask_new_sms_code: 'Pedir novo código SMS',
    retry_sms_msg1: 'Peça um novo código dentro de ',
    retry_sms_msg2: ' segundos',
  },
  appointment_confirmed: {
    title: 'Confirmado!',
    add_google_calendar: '+ Adicionar ao calendário Google',
    get_directions: 'Obter Direcções',
  },
  home_page: {
    address_description: `O Student Hub está localizado no rés-do-chão do Edifício da Faculdade de Medicina, na Rua Larga.`,
    openhours_description: `O horário dos serviços de apoio é de segunda a sexta-feira das 9h30 às 13h00 e das 14h00 às 17h00.<br>O horário das salas disponibilizadas pelo Student Hub é de segunda a sexta-feira das 9h00 às 20h00.`,
    dashboard: {
      your_tickets: 'As tuas senhas',
      next_appointment: 'Próxima marcação',
      appointments: 'marcações',
      see_all_appointments: 'Ver todas',
      title_all_appointments: 'Todas as marcações',
    },
    queues: 'Serviços',
    get_directions: 'Obter Direcções',
    address: 'Morada',
    map_location: 'Ver localização no mapa',
    schedule: 'Horário',
    thespace: 'O espaço',
    queue_card: {
      get_ticket: 'Tirar Senha',
      ticket_not_available: 'Não é possível tirar senha',
      login_to_get_ticket: 'Inicie sessão para',
      login_to_get_ticket_part2: 'tirar senha',
    },
  },
  needs_authentication: {
    title: 'Autentica-te',
    message: 'A emissão de senhas online está reservada à comunidade universitária.',
  },
  select_services: {
    title: 'Seleccione os serviços',
    ticket_message: 'Escolha os vários serviços em que quer tirar senha',
    appointment_message: 'Escolha os vários serviços em que quer fazer marcação',
    queue_needs_authentication: 'Inicie sessão para conseguir fazer uma marcação para este serviço',
  },
  add_appointment_details: {
    title: 'Fazer Marcação',
    select_subject: 'Escolha o(s) assunto(s)',
    optional: 'Opcional',
    message_subject_part1: 'Ajude-nos a filtrar o seu pedido seleccionando um',
    message_subject_part2: '(ou vários) assunto(s)',
    add_details: 'Descreva o assunto',
    mandatory: 'Obrigatório',
    message_details: 'Descreva detalhadamente o que prentende',
    message_option_phone: 'Indique-nos o seu número de telemóvel',
    placeholder_details: 'Como podemos ajudar?',
    full_name: 'Nome completo',
    email: 'Endereço de email',
    phone: 'Número de telemóvel',
  },
  select_appointments_schedule: {
    title: 'Todos os horários disponíveis',
    day_summary_slots: 'vagas',
  },
  smart_schedule_results: {
    title: 'Sugestões de horários',
    message: 'Encontrámos os seguintes horários que lhe podem interessar',
    see_all: 'Ver todos os horários',
    error_message: 'Não conseguimos determinar um horário automaticamente.<br />Por favor, escolha dentro das opções:',
  },
  smart_schedule_selection: {
    title: 'Sugestões de horários',
    message: 'Seleccione as suas preferências de modo a encontrarmos o melhor horário.',
    confort_option: 'Conforto',
    confort_option_message: 'Ver vagas de horário próximas umas das outras para ser atendido no mesmo dia.',
    urgent_option: 'Urgente',
    urgent_option_message: 'Ver as próximas vagas disponíveis',
    very_urgent_option: 'Muito Urgente',
    very_urgent_option_message: 'Quero ser atendido agora',
    see_all_schedule: 'Ver todos os horários',
  },
  ticket_confirmed: {
    title: 'As tuas senhas',
    get_directions: 'Obter Direcções',
  },
  bottom_bar: {
    get_ticket: 'Tirar Senha',
    authenticate: 'Autenticar',
    get_appointment: 'Fazer Marcação',
    close: 'Fechar',
    sendSMScode: 'Enviar código SMS',
    getAppointment: 'Fazer marcação',
    confirmLater: 'Confirmar mais tarde',
    getTickets: 'Tirar senhas',
    next: 'Seguinte',
  },
  ticket: {
    wait: 'Aguarde',
    post: 'Mesa',
  },
  error_messages: {
    makeAppointment: 'Ocorreu um erro ao fazer a marcação. Tenta de novo mais tarde.',
    sendSMSCode: 'Pedimos desculpa mas o SMS não pode ser enviado. Tenta de novo mais tarde.',
    getTickets: 'Ocorreu um erro ao processar o seu pedido.',
    confirmAppointments: 'Ocorreu um erro ao confirmar a marcação. Tenta de novo mais tarde.',
    cancelAppointment: 'Ocorreu um erro ao cancelar a marcação. Tenta de novo mais tarde.',
    scheduled_call: 'Já tem uma marcação para este serviço.<br />Se quiser mudar, cancele a marcação anterior.',
    getAppointmentStatus: 'Ocorreu um erro ao ir buscar a informação sobre a marcação. Tenta de novo mais tarde.',
    confirmationCode: 'O código de confirmação não é válido.',
  },
  success_messages: {
    confirmAppointments: 'As marcações estão confirmadas!',
    cancelAppointment: 'A marcação foi cancelada com sucesso.',
  },
  appointment_status: {
    title: 'Estado da Marcação',
    unnavialable_info: 'A informação sobre a marcação não está de momento disponível. Tenta de novo mais tarde',
  },
  appointment: {
    tooltip_how_to_confirm: 'Confirme a marcação clicando no e-mail enviado',
    status_not_confirmed: 'Não confimada',
    status_canceled: 'Cancelada',
    cancelation_message: 'Motivo de cancelamento',
    cancel_appointment: 'Cancelar Marcação',
    add_google_calendar: '+ Adicionar ao calendário da Google',
  },
  confirm_email: {
    one_step_more: 'Só mais um passo!',
    title: 'Confirmar e-mail',
    message_part1: 'Foi enviado um e-mail para',
    message_part2: 'Por favor, carrega no link que te enviámos para confirmar que o e-mail é teu.',
    open_webmail: 'Abrir ',
  },
  digital_ticket: {
    title: 'A tua senha',
  },
  navigation_text: {
    selectSubject: 'Escolha o(s) assunto(s)',
    select_services: 'Escolha o(s) serviço(s)', //Seleccione os serviços
    schedule_suggestions: 'Sugestões de horários', //Sugestões de horários
    select_schedule: 'Escolha o horário', //Escolha o horário
  },
  weekdays: {
    0: 'Segunda-feira',
    1: 'Terça-feira',
    2: 'Quarta-feira',
    3: 'Quinta-feira',
    4: 'Sexta-feira',
    5: 'Sábado',
    6: 'Domingo',
  },
}

export default pt
