import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import ShortKey from 'vue-shortkey'
import VueScrollactive from 'vue-scrollactive'
import VClamp from 'vue-clamp'
import VueClipboard from 'vue-clipboard2'
import VueNativeSock from 'vue-native-websocket'
import VCalendar from 'v-calendar'
import VueNativeNotification from 'vue-native-notification'
import JsonViewer from 'vue-json-viewer'

import App from '@/App.vue'
import messages from '@/i18n'
import router from '@/router'
import store from '@/store'

import SvgIcon from 'vue-svgicon'
import Buefy from 'buefy'
// import VuePlyr from 'vue-plyr'
import Vuelidate from 'vuelidate'
import Nprogress from 'nprogress'

import device from '@/fw-modules/fw-core-vue/utilities/device'

import '@/fw-modules/fw-core-vue/filters'
import '@/fw-modules/fw-core-vue/ui/icons'

import Heading from '@/fw-modules/fw-core-vue/ui/components/text/Heading'
import Button from '@/fw-modules/fw-core-vue/ui/components/buttons/Button'
import ButtonDropdown from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonDropdown'
import Label from '@/fw-modules/fw-core-vue/ui/components/text/Label'
import Badge from '@/fw-modules/fw-core-vue/ui/components/text/Badge'
import Dot from '@/fw-modules/fw-core-vue/ui/components/animation/Dot'
import DotActive from '@/fw-modules/fw-core-vue/ui/components/animation/DotActive'
import BlockPanelHeader from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPanelHeader'
import ListItems from '@/fw-modules/fw-core-vue/ui/components/lists/ListItems'
import Panel from '@/fw-modules/fw-core-vue/ui/components/panels/Panel'
import PanelInfo from '@/fw-modules/fw-core-vue/ui/components/panels/PanelInfo'
import Card from '@/fw-modules/fw-core-vue/ui/components/cards/Card'
import FwTip from '@/fw-modules/fw-core-vue/ui/components/text/Tip'
import Tag from '@/fw-modules/fw-core-vue/ui/components/text/Tag'
import FwCardThumb from '@/fw-modules/fw-core-vue/ui/components/cards/FwCardThumb'
import FwAvatar from '@/fw-modules/fw-core-vue/ui/components/FwAvatar'
import FwEditor from '@/fw-modules/fw-core-vue/ui/components/FwEditor'
import FwModal from '@/fw-modules/fw-core-vue/ui/components/modals/FwModal'
import PanelPageHeader from '@/fw-modules/fw-core-vue/ui/components/panels/PanelPageHeader'

import FwEnvConfig from './config'

import 'typeface-inter'

// FW Core Components
Vue.component('FwHeading', Heading)
Vue.component('FwButton', Button)
Vue.component('FwButtonDropdown', ButtonDropdown)
Vue.component('FwDot', Dot)
Vue.component('FwDotActive', DotActive)
Vue.component('FwLabel', Label)
Vue.component('FwBlockPanelHeader', BlockPanelHeader)
Vue.component('FwListItems', ListItems)
Vue.component('FwPanel', Panel)
Vue.component('FwPanelPageHeader', PanelPageHeader)
Vue.component('FwBadge', Badge)
Vue.component('FwTag', Tag)
Vue.component('FwCard', Card)
Vue.component('FwCardThumb', FwCardThumb)
Vue.component('FwAvatar', FwAvatar)
Vue.component('FwEditor', FwEditor)
Vue.component('FwModal', FwModal)
Vue.component('FwTip', FwTip)
Vue.component('FwPanelInfo', PanelInfo)

Vue.component('VClamp', VClamp)
Vue.use(Buefy, {
  defaultIconComponent: 'faicon',
  defaultIconPack: 'fas',
})

Vue.use(VueI18n)
Vue.use(SvgIcon, { tagName: 'icon' })
Vue.use(Vuelidate)
Vue.use(Nprogress)
Vue.use(JsonViewer)
Vue.use(VueScrollactive)
Vue.use(VueClipboard)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locale: 'pt',
})

Vue.use(ShortKey)

Vue.use(VueNativeSock, FwEnvConfig.wsUrl, {
  store: store,
  format: 'json',
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: Infinity, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 5000, // (Number) how long to initially wait before attempting a new (1000)
})

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
})

// Vue.use(VuePlyr, {
//   plyr: {
//     fullscreen: { enabled: true },
//   },
//   emit: ['ended'],
// })

Nprogress.configure({ showSpinner: false, minimum: 0.1 })

Vue.config.productionTip = false
Vue.config.performance = process.env.VUE_APP_IS_STAGING || false

// Inicializar i18n
let language = localStorage.getItem('settings.language')
if (!language) store.dispatch('setLanguage', 'pt')
const i18n = new VueI18n({
  locale: language,
  fallbackLocale: 'pt',
  messages,
})

// Define a autorização, caso exista
Vue.prototype.$http = axios
const token = localStorage.getItem('session.token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.prototype.$device = device

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
